:root {
  --padding-small: 8px;
  --padding-medium: 16px;
  --padding-large: 24px;

  --margin-small: 8px;
  --margin-medium: 16px;
  --margin-large: 24px;

  --color-primary: #007bff;
  --color-secondary: #6c757d;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --color-warning: #ffc107;
  --color-info: #17a2b8;
  --color-light: #f8f9fa;
  --color-dark: #343a40;
  --color-white: #ffffff;
  --color-gray: #6c757d;
  --color-black: #000;
  --color-text-primary: #333;
  --color-text-secondary: #666;
  --color-text-custom: #93969F;
}

.App {
  text-align: center;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.text-white{
  color: var(--color-white);
}
.text-black{
  color: var(--color-black);
}
.text-gray{
  color: #93969F;
}
/* .App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
} */

.App-link {
  color: #61dafb;
}
/* navbar css */
/* Navbar.css */

.navbar {
  top: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content:left !important;
  align-items: left;
  background-color: #fff;
  max-width:100%;
padding-left: 90px !important;
  z-index: 1000; /* Ensure it stays on top of other elements */
}
#list-btn{
  justify-content: end !important;
  
}
#mobile-view{
  display: none;
}
#desktop-view{
  display: block;
}
.navbar-logo img {
  height: 55px; /* Adjust the height as needed */
  margin: 38px;
  max-width: 100%;
}
.navbar-toggler {
  display: none;
  background: none;
  /* margin-left: 100px; */
  border: none;
  justify-content: end !important;
  font-size: 1.5rem;
}

.navbar-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  /* justify-content: center; */
}
.navbar-list-mobile {

  list-style: none;

  justify-content: center;
}
.navbar-item {
  margin: 0 15px;
  /* padding: 14px 20px; */
}

.navbar-item a {
  text-decoration: none;
  color: #857FA3;
  font-weight: 600;
  /* text-align: center;
  text-decoration: none;
  display: block; */
}



.navbar-item a:hover {
  /* background-color: #ddd; */
  color:#070030;
}
.navbar-item.active a {
  /* background-color: #070030; Active background color */
  color: #070030;
}


.get-started-button:hover {
  background-color: #070030;
}


.get-started-button {
  background-color: #070030;
  color: #EBEBED;
  font-size: 12px;
  font-weight: 600;
  border: none;
  justify-content: end !important;
  align-items: center;
  border-radius: 50px 50px 50px 50px;
  margin-left: 150px !important;
  padding: 10px 50px 10px 50px;
  gap: 10px;
  cursor: pointer;
}

.small-text{
  font-size: 14px;
  font-weight: 600;
  display: flex;
}
.small-text-home{
  font-size: 14px;
  font-weight: 600;
  display: flex;
}
.text-color{
  color: #fff;
}
/* .home-image{
 
  height:400px;
} */
.home-image {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  padding-left: 50px;
  
   /* Center vertically */
}
.home-testi-image{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;

}
.home-testi-image img{
  max-width: 100%;
  height:440px;
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.home-image img {
  max-width: 100%;
  height:auto;
}
.security-image {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  
   /* Center vertically */
}
.security-image img{
  max-width: 100%;
  height:auto;
}
.home-container{
  margin-top: 100px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}

.col-md-6,
.col-md-6 {
  padding-left: 0 !important;
  padding-right: 0 !important;

}
/* .get-started-button:hover {
  background-color: #45a049;
} */
/* end navbar css */
/* home component css */
.about-heading{
  font-size: 56px;
  font-weight: 500;
  line-height: 78px;
  color: #040018;
}
.test-heading{
  font-size: 56px;
  font-weight: 500;
  line-height: 78px;
 
}
.home-heading{
  font-size: 64px;
  font-weight: 500;
  line-height: 76px;
  color: #EBEBED;
}
.home-text-content{
  margin-left: 60px;
}
.approve-text{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-black);
}
.about-gridDot{
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100%; /* Adjust as needed */
  width: 100%; /* Adjust as needed */
  /* background-color: #040018; */
  height:370px;
}
.about-text{
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #93969F;
}
.homeContainer {
  margin-top: 0px;
  padding-top:120px ;
  background-color: #243B8D;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100% !important;

}

.homeText {
  display: flex;  
  color: #EBEBED;
  
  
  margin-top: 100px;
  /* margin-left: 70px; */
  flex-direction: column;
  align-items: flex-start; /* Align text to the start */

  justify-content: flex-start;
  text-align: left;
}
.text-start {
  text-align: left; /* Ensure text is aligned to the left */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

#text-section {
  margin: 0; /* Ensure no extra margin */
  padding: 0; /* Ensure no extra padding */
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}

.homeText .mainTitle {
  color: #EBEBED;
  display: flex;
  max-width: 100%; /* Ensure the text doesn't overflow */
  font-weight: 400;
  text-align: left;
  font-size: 64px;
}
.home-container{
  margin-top: 110px !important;
}
.home-button {
  margin-top: 10px; /* Adjust the spacing as needed */
  background-color: #070030;
  color: #EBEBED;
  font-size: 15px;
  font-weight: 600;
  border: none;
  /* margin-left: 15px; */
  justify-content: center;
  border-radius: 28px;
  padding: 20px 50px 20px 50px;
  cursor: pointer;
}

.justify-content-start {
  display: flex;
  justify-content: flex-start;
}
.round-buttons-container {
  display: flex;
  align-items: center; /* Align buttons with text */
  position: relative;
  margin-left: 12px;
}

.round-button {
  width: 14px; /* Adjust the size as needed */
  height: 14px; /* Ensure width and height are equal to create a circle */
  border-radius: 50%; /* Make it round */
  position: relative;
}

.round-button.yellow {
  background-color: #9780F2; /* Yellow button */
  margin-right: -5px; 
  z-index: 1; /* Ensure it overlaps correctly */
}

.round-button.green {
  background-color: #FFD028; /* Green button */
  /* Adjust this value to control the overlap */
  z-index: 0; /* Ensure it overlaps correctly */
}
.containerSection {
  /* background-image: url('/images/DotGrid.png'); */
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  /* height: 820px; Adjust as needed */
  width: 100%; /* Adjust as needed */
  background-color: #fff;
  height:400px;
}
.horizontal-line{
  margin-right: 25%;
  background-color: #B6BED9;
}

.small-button {
  margin-top: 10px; /* Adjust the spacing as needed */
  background-color: #2B2250;
  color: #C0B3F7;
  font-size: 24px;
  font-weight: 700;
  border: none;
  justify-content: center;
  border-radius: 25px;
  padding: 10px 35px 10px 35px;
  cursor: none;
}
.arrow-icon{
  color: #fff;
}
.smallyellow-button{
  margin-top: 10px; /* Adjust the spacing as needed */
  background-color: #FFD028;
  color: #383A3E;
  width: 82px;
  height: 54px;
  /* font-size: 24px; */
  font-weight: 700;
  border: none;
  justify-content: center;
  border-radius: 999px;
  padding: 10px 30px 10px 30px;
  cursor: none;
}
.section-three{
  background-color: #040018;
  padding-bottom: 100px;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  /* text-align: left; */

}
.nav-logo{
  /* background-color: #fff; */
}
.text-left {
  text-align: left;
}
.section-three-header{
  text-align: left;
  margin: 0;
  padding: 0;
}

.section-three-text{
  text-align: left;
  margin: 0;
  padding: 0;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;  /* Width of the box */
  height: 60px; /* Height of the box */
  border: 1px solid #070030;
  border-radius: 8px; /* Small border radius */
  background-color: #070030; /* Background color for the box */
}
.aboutSection{
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  text-align: left;
}
.about-content{
  margin-left: 40px;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
}
.check-icon-text{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #383A3E;
}
.check-icon {
  color: #070030; /* Use the custom color */
  width: 25px;
  height: 25px;
}
.about-content-text{
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: #040018;
  text-align: left;
}
.about-section{
  margin-top: 160px !important;
}
/* services css */
.service-section{
  background-color: #E6E1FC;
  margin-top: 110px !important;
  /* margin-top: 150px; */
  padding-bottom: 150px;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.privacy-section{
  background-color: #243B8D;
  margin-top: 150px;
  padding-bottom: 0px;
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.service-container{
  margin-top: 110px !important;
}
.service-text{
  text-align: end;
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  margin: 0;
  padding: 0;
  color: #93969F;
}
.card {
  background-color: #f8f9fa;
  border: none;
  margin-left: 10px;
  margin-top: 30px;
  /* height:350px !important; */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  transition: transform 0.2s;
}
#consult-card{
  background-color: #243B8D;
  border: none;
  /* height:350px !important; */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  transition: transform 0.2s;
}
.consult-card-img{
  background-size:cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-img::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5px; /* Diameter of the dot */
  height: 0.5px; /* Diameter of the dot */
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.card-img{
  background-size:cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 80%; /* Adjust as needed */
  width: 80%; 
}
.card-section{
  margin-top: 80px;
}
.card:hover {
  transform: translateY(-10px);
}
.card-body{
  margin: 25px;
}
.card-icon {
  color: #007bff;
}

.card-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

/* Hover styles */
.card:hover {
  background-color: #243B8D;
}

.card:hover .card-title {
  color: #ffffff; /* White */
}

.card:hover .card-subtitle {
  color: #C5C6CD; /* Subtitle color */
}
.card:hover .card-subtitle-one{
  color: #C5C6CD; /* Subtitle color */ 
}

#consult-card-subtitle{
  text-align: end;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
  padding: 0;
  color: #C5C6CD !important;
}
.card-subtitle{
  text-align: end;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
  padding: 0;
  color: #93969F !important;
}
.card-subtitle-one{
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
  padding: 0;
  color: #93969F !important;
}
.flex-grow-1 {
  flex-grow: 1;
}
.consult-card-title{
  margin-top: 35%;
}
.card-title{
  margin-top: 40%;
}
.whyus-section{
  margin-top: 160px !important;
}
.card-whyus{
  background-color: #070030;
  border: none;
  width:320px;
  display: flex;

  /* height:350px !important; */
  padding: 40px, 60px, 40px, 60p;
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  transition: transform 0.2s;
}
.star {
  font-size: 42px;
  color: #FFD028;
  /* cursor: pointer; */
}
.card-whyus .content{
  margin-left: 50px;
}
.card-whyus:hover {
  transform: translateY(-10px);
}
.text-name{
  font-weight: 600;
  font-size: 20;
  line-height: 28px;
  color: #fff;
}
.rate-number{
  font-size: 76px;
  font-weight: 500;
  line-height: 91px;
  color: #fff;
}
.rate-subNumber{
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #93969F;
}
.round-buttonContent{
  width: 64px; /* Adjust the size as needed */
  height: 64px; /* Ensure width and height are equal to create a circle */
  border-radius: 99px; /* Make it round */
  position: relative;
  margin-top: 10px; /* Adjust the spacing as needed */
  color: #fff;
  /* font-size: 24px; */
  font-weight: 700;
  border: none;
  justify-content: center;
  border-radius: 50%;
  padding: 16px 35px 18px 35px;
  cursor: none;
  /* color: #5745A0; */
}
.round-buttons-content-container {
  display: flex;
  align-items: center; /* Align buttons with text */
  position: relative;
  margin-left: 12px;
}
.round-buttonContent.firstgrape {
  background-color: #5745A0; /* Yellow button */
  /* border: 1px solid #070030;  */
  z-index: 1; /* Ensure it overlaps correctly */
}

.round-buttonContent.grape {
  background-color: #9780F2; /* Green button */
  margin-left: -18px;
  border: 1px solid #070030; 
  /* Adjust this value to control the overlap */
  z-index: 0; /* Ensure it overlaps correctly */
}
.lists{
  margin-top: 72px;
}
.container-list{
  background-color: #F2F5FF;
  height:88px;
}

.container-list:hover{
  background-color: #243B8D;
}
.container-list:hover:hover .list-text{
  color: #fff;
}
.list-text-one{
  color: #fff;
  text-align: start !important;
  font-size: 24px;
  display:flex;
  margin: 0;
  padding: 0;
  line-height: 33px;
  font-weight: 700;
}
.list-number{
  color: #9780F2;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.list-text{
  color: #040018;
  text-align: start !important;
  font-size: 24px;
  display:flex;
  margin: 0;
  padding: 0;
  line-height: 33px;
  font-weight: 700;
}
.small-privacytext{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  color: #fff;
}

.privacy-heading{
  font-size: 56px;
  display: flex;
  font-weight: 500;
  line-height: 78px;
  color: #fff;
}
.privacy-text{
  text-align: end;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  padding: 0;
  color: #C5C6CD;
}
.privacy-content{
  margin-top: 230px;
}
.privacy-roundyellow-button{
  width: 64px; /* Adjust the size as needed */
  height: 64px; /* Ensure width and height are equal to create a circle */
  border-radius: 50%; /* Make it round */
  position: relative;
  /* margin-top: 10px; Adjust the spacing as needed */
  color: #fff;
  background-color: #9780F2;
  /* font-size: 24px; */
  font-weight: 700;
  border: none;
  justify-content: center;
  border-radius: 99px;
  padding: 18px 35px 18px 35px;
  cursor: none;
}
.testimonial-cards{
  background-color: #F2F5FF;
  border: none;
  width:316px;
  /* height: 410px; */
  display: flex;

  /* height:350px !important; */
  padding: 40px, 60px, 40px, 60p;
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  /* transition: transform 0.2s; */
  transition: opacity 0.2s ease;
}
.testimonial-card-head{
  background-color: #F2F5FF;
  border: none;
  width:316px;
  height: 140px;
  display: flex;
  padding: 40px, 60px, 40px, 60p;

}
.testimonial-card-head-one{
  background-color: #F2F5FF;
  border: none;
  width:316px;
  height: 180px;
  display: flex;
  padding: 40px, 60px, 40px, 60p;

}
.testimonial-cards:hover{
  background-color: #243B8D;
  border: none;
  width:316px;
  /* height: 410px; */
  display: flex;

  /* height:350px !important; */
  padding: 40px, 60px, 40px, 60p;
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  transition: transform 0.2s;
}
.testimonial-rate{
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}
.testimonial-cards:hover .testimonial-rate{
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: #fff;
}
.testimonial-subrate{
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #93969F;
}
.testimonial-cards:hover .testimonial-subrate{
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #93969F;
}
.testimonial-text{
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #93969F;
  display: flex;
  text-align: left;
}
.testimonial-cards:hover .testimonial-text{
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #C5C6CD;
  display: flex;
  text-align: left;
}
.testimonial-cards:hover .testimonial-name{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #fff;

}
.testimonial-name{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #040018;
}
.testimonial-icon{
  font-size: 25px !important;
  width: 35px;
  height: 35px;
}
.testimonial-subtext{
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #93969F;
}
.testimonial-subtext-one{
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #93969F;
}
.testimonial-btn{
  width: 64px; /* Adjust the size as needed */
  height: 64px; /* Ensure width and height are equal to create a circle */
  border-radius: 50%; /* Make it round */
  position: relative;
  /* margin-top: 10px; Adjust the spacing as needed */
  color: #fff;
  background-color: #9780F2;
  /* font-size: 24px; */
  font-weight: 700;
  border: none;
  justify-content: center;
  border-radius: 99px;
  padding: 15px 35px 25px 35px;
  cursor: none;
}
.section-started{
  margin-top: 80px;
  
}
.testimonial-cards-head {
  opacity: 0.3;
  transition: opacity 0.5s ease;
}

.testimonial-cards-head.show {
  opacity: 1 !important;
}
.startContainer-image{
  background-color: #007bff;
  height:500px ;
}
.starter-container{
  margin-top: 100px;
}
.StartedSection {
  background-color: #E6E1FC;
  height:440px;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.Started-container{
  margin-top: 80px;
}
.starter-image{
  background-color: #495CA0;
  height: 500px;

}
.section-end{
  display: flex; 
  height: 500px; 
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 15px;
  background-color: #E6E1FC;
}
.footer{
  display: flex; 
  /* height: 400;  */
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 15px;
  background-color: #243B8D;
}
.sub-heading{
  font-weight: 600;
  font-size:20px;
  line-height: 28px;
}
.footer-section{
  margin-top: 50px;
}
.menu-list li {
  color: white;
 
}
.menu-list{
  text-decoration: none;
  list-style-type: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}
.menu-list a {
  color: white;
  text-decoration: none;
}
.copyright{
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
 
}
.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  margin-left: 10px;
  transition: background-color 0.3s;
}
.phone-icon{
  color:#9780F2;
  width:24px;
  height:24px;
  margin-right: 10px;
}
.icon.facebook {
  background-color: #3b5998;
}

.icon.instagram {
  background-color: #E1306C;
}

.icon.linkedin {
  background-color: #0077b5;
}
.icon.youtube {
  background-color: #FF0000;
}
.icon.twitter {
  background-color: #1DA1F2;
}

.icon:hover {
  opacity: 0.8;
}

.footer-line{
  color: #495CA0;
  /* height:1px; */
  border: 1px solid #495CA0;
}
.logo-title{
  font-size: 48px;
  font-weight: 500;
  line-height: 67px;
 
  color: #fff;
}
.description{
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;

  color: #EBEBED;
}
/* end service css */
/* end home component css */
/* lazy loading */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out ;
}

.lazy-load {
  opacity: 1;
}
/* end */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (min-width: 324px) and (max-width: 767px) {
 
  .navbar {
    top: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content:left !important;
    align-items: left;
  
    background-color: #fff;
    max-width:100%;
  padding-left: 0px !important;
  padding-top: 15px !important;
    z-index: 1000; /* Ensure it stays on top of other elements */
  }
  
  .nav-logo{
    text-align: left !important;
    display: flex !important;
    margin: 0 !important;
    padding: 0  !important;
    justify-content:start !important;
  }
  .navbar-toggler {
    display: block !important;
    justify-content: end !important;
   
}
#hm-btn{
  display: flex;
justify-content: center !important;
align-items: center !important;
/* margin: 0 !important; */
padding: 0 !important;
margin-bottom: 40px;
}
#mobile-view{
  display: block !important;
}
#desktop-view{
  display: none !important;
}

.home-text-content{
  margin-left: 0px;
}
.list-number{
  justify-content: flex-start !important;
  text-align: left;
}
.section-three .container {
  flex-direction: column;
  text-align: center;
  width:auto !important
}
.section-three .row {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.approve-text {
  text-align: center;
}

.approve-container .row {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
#testi-btn{
  display: flex;
  margin-left: 0 !important;
  justify-content: center !important;
}
.approve-container .col-md-4 {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
}
.section-three .col-md-3 {
  margin-bottom: 30px;
  width: 100%;
}
.home-heading{
  font-size: 64px;
  font-weight: 500;
  line-height: 76px;
  color: #EBEBED;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-image {
  padding-left: 0 !important;
  margin-left: 0 !important;
  text-align: center;
}
.copyright{
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 0 !important;
}
.home-image img {
  width: 100%;
  height: auto;
}
.navbar-collapse {
    flex-direction: column;
    align-items: flex-start;
    display: none;
}
.about-content-text{
  margin-top:20px;
}
.navbar-collapse.show {
    display: flex;
}

.navbar-list {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.navbar-item {
    width: 100%;
    text-align: left;
    margin: 10px 0;
}
.navbar-bottom-item{
  width: 100%;
  text-align: left;
  margin: 10px 0;
}

.navbar-item a {
  color: #000; /* Default link color */
  text-decoration: none;
  padding: 10px;
  display: block;
}

.navbar-item.active a {
  color: #070030;
}

.navbar-list-mobile {

  background-color: #f8f9fa; /* Background color for mobile nav */
  justify-content: center !important;
  margin: 0 !important;
  padding-left: 100px !important;
   padding-right: 100px !important;
 
}
.card-whyus{
justify-content: center !important;
margin-left: 10px;
align-items: center !important;
}
.why-us-btn{
  margin: 20px;
}
.star {
  font-size: 42px;
  color: #FFD028;
  /* cursor: pointer; */
}
.card-whyus .content{
  margin-left: 50px;
}
.card-whyus:hover {
  transform: translateY(-10px);
}
.navbar-item {
  text-align: center;
}
.home-testi-image img{
  
  height:auto;
  justify-content: center !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding-right: 20px !important;
  display: flex;

}
.navbar-item a:hover {
  background-color: #e9ecef; /* Hover background color */
}
  .get-started-button {
    background-color: #070030;
    color: #EBEBED;
    font-size: 14px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    padding: 12px 24px;
    margin: 20px auto;
    cursor: pointer;
  }

  
  .small-privacytext{
    justify-content: left !important;
    margin-left: 15px;
  }

  .text-color {
    color: #fff;
  }

  .home-image, .security-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-image img, .security-image img {
    max-width: 100%;
    height: auto;
  }

  .home-container {
    margin-top: 0px !important;
  }

  .row {
    margin: 0;
  }

  .col-md-6 {
    padding: 0;
  }

  .about-heading, .home-heading{
    font-size: 36px;
    display: flex;
    font-weight: 500;
    line-height: 48px;
    justify-content: left !important;
    margin:20px ;
  }
  .test-heading{
    font-size: 30px !important;
    display: flex;
    font-weight: 500;
    line-height: 48px;
    justify-content: center !important;
    margin:20px ;
  }
  .privacy-heading {
    font-size: 36px;
    display: flex;
    font-weight: 500;
    line-height: 48px;
    justify-content: left !important;
    margin:20px ;
  }
  .testimonial-cards {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 90%; /* Adjust this value as needed to control the width */
  }



  .col-md-6.col-sm-12.d-flex.flex-wrap {
    justify-content: center;
  }
  .approve-text, .about-text, .service-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .about-gridDot {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 240px;
    /* background-color: #040018; */
  }
.service-content{
  margin: 20px;
}
.service-btn{
  margin: 15px;
}
  .homeContainer {
    margin-top: 80px;
    background-color: #243B8D;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
  }

  .homeText {
    color: #EBEBED;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }

  .homeText .mainTitle {
    color: #EBEBED;
    font-size: 36px;
    font-weight: 400;
    text-align: left;
    max-width: 100%;
  }

  .lists {
    text-align: center;
   
  }
  
  .container-list {
    justify-content: left;
    padding:10px ;
  }
  .container-list .list-text{
    justify-content: center;
    padding-left:20px ;
  }

  .list-img {
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
  }
  .col-md-2, .col-md-8, .col-sm-2, .col-sm-8 {
    flex: 0 0 auto;
    text-align: center;
  }
  .justify-content-start {
    display: flex;
    justify-content: flex-start;
  }

  .round-buttons-container {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }

  .round-button {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
  }

  .round-button.yellow {
    background-color: #9780F2;
    z-index: 1;
  }

  .round-button.green {
    background-color: #FFD028;
    z-index: 0;
  }

  .containerSection {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-color: #fff;
    height: auto;
  }

  .horizontal-line {
    margin-right: 25%;
    background-color: #B6BED9;
  }

  .small-button, .smallyellow-button, .round-buttonContent {
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    padding: 8px 16px;
  }
  .small-text {
   
    display: flex;
    text-align: left !important;
    margin-left: 15px;
   
  }
  .small-text-home{
   
    display: flex;
    text-align: left !important;
    margin-left: 15px;
  
  }
  .section-three, .service-section, .privacy-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .about-content{
    margin: 0 auto;
    justify-content: center !important;
    padding-top: 120px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .aboutSection,  .card-section, .lists, .privacy-content, .footer {
    margin: 0 auto;
    justify-content: center !important;
    padding: 0;
    width: 100%;
  }

  .card, .testimonial-cards, .testimonial-cards-one {
    width: 100%;
    margin: 20px 0;
  }

  .testimonial-text, .testimonial-text-one {
    text-align: center;
  }

  .icon-box, .round-buttonContent {
    width: 40px;
    height: 40px;
  }

  .phone-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .list-text-one, .list-text, .testimonial-name, .testimonial-name-one {
    font-size: 16px;
    font-weight: 600;
  }

  .footer-section {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
  #foot-text{
    text-align: left !important;
    display: flex;
    margin-right: 30px;
  }
  .icon {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }
  .logo-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    align-items: center !important;
    justify-content: center !important;
  }
  .menu-list{
    text-decoration: none;
    list-style-type: none;
    color: #fff;
    justify-content: center !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
  .description {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0 !important;
  }

}

@media (min-width: 767px)  and (max-width: 1024px)  {
  #desktop-view {
      display: block !important;
  }
  .navbar {
    top: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content:left !important;
    align-items: left;
  
    background-color: #fff;
    max-width:100%;
  padding-left: 20px !important;
  padding-top: 15px !important;
    z-index: 1000; /* Ensure it stays on top of other elements */
  }
  .navbar-list {
    display: flex;
    list-style: none;
    margin: 0;

  }
  .card-section .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .get-started-button {
    background-color: #070030;
    color: #EBEBED;
    font-size: 10px;
    margin-left: 0;
    font-weight: 400;
    border: none;
    justify-content: left !important;
    align-items: center;
    border-radius: 28px;
    cursor: pointer;
  }

  .navbar-logo{
    margin-left: 0 !important;
    margin-right: 0;
  }
  #mobile-view {
      display: none !important;
  }
  .navbar-toggler {
      display: none;
      position: relative;
      justify-content: end !important;
  }
  .test-heading{
    font-size: 32px;
    font-weight: 500;
    line-height: 58px;
   
  }
  #hm-btn {

    margin: 0 !important;
    padding: 0 !important;
    
  }
  .containerSection {
    height: 300px !important; /* Adjust the height as needed */
  }
  #text-section {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  }

  .home-text-content{
    margin-left: 0px;
    text-align: left !important;
  }
  .section-three .container {
    flex-direction: column;
    text-align: center;
    width: 100% !important;
    padding-left: 40px;
  }
  .section-three .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .section-three .col-md-3 {
    margin-bottom: 30px;
    padding: 15px;
  }
  .section-three .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .approve-text {
    text-align: left;
  }
  .approve-container .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .approve-container .col-md-4 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  .approve-container img {
    display: block;
    margin: 0 auto;
  }
  


  .home-image {
    padding-left: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .home-image img {
    width: 100%;
    height: auto;
  }
  .navbar-collapse {
    flex-direction: column;
    align-items: flex-start;
    display: none;
  }
  .about-content-text {
    margin: 20px;
  }
  .navbar-collapse.show {
    display: flex;
  }
  .navbar-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .navbar-nav li.ml-auto {
    margin-left: auto;

  }
  #list-btn{
   display: none !important;
   justify-content: flex-end !important;
   align-items: end !important;    
  }

  .navbar-item {
    width: 100%;
    text-align: left;
    margin: 10px 0;
  }
  .navbar-item a {
    color: #000; /* Default link color */
    text-decoration: none;
    padding: 10px;
    display: block;
  }
  .navbar-item.active a {
    color: #070030;
  }
  .navbar-list-mobile {
    background-color: #f8f9fa; /* Background color for mobile nav */
  }
  .card-whyus {
    justify-content: center !important;
    margin-left: 10px;
    align-items: center !important;
  }
  .why-us-btn {
    margin: 20px;
  }
  .star {
    font-size: 42px;
    color: #FFD028;
    /* cursor: pointer; */
  }
  .card-whyus .content {
    margin-left: 50px;
  }
  .card-whyus:hover {
    transform: translateY(-10px);
  }
  .navbar-item {
    text-align: center;
  }
  .navbar-item a:hover {
    background-color: #e9ecef; /* Hover background color */
  }

  .small-text {
  
    display: flex;
    text-align: left !important;
    margin-left: 15px;
   
  }
  .small-text-home{

    display: flex;
    text-align: left !important;
    margin-left: 15px;
  
  }
  
  .small-privacytext {
    justify-content: left !important;
  }
  .privacy-heading {
    justify-content: left !important;
    text-align: left !important;
    font-size: 46px;
    display: flex;
    font-weight: 500;
    line-height: 58px;
    color: #fff;
   margin-left:20px ;
  }
  .privacy-content {
    
    justify-content: end !important;
    align-items: end !important;
   
  }
  .text-color {
    color: #fff;
  }
  .home-image img{
    height:300px !important;
  }
  .security-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-section .card{
    height:350px;
  }
  .card-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  .home-image img, .security-image img {
    max-width: 100%;
    height: auto;
  }
  .home-container {
    margin-top: 60px;
  }
  .approve-content{
    display: flex !important;
    justify-content: start !important;
  }
  .row {
    margin: 0;
  }
  .col-md-6 {
    padding: 0;
  }
  .about-heading, .home-heading {
    font-size: 36px;
    display: flex;
    font-weight: 500;
    line-height: 48px;

    margin: 20px;
  }
  .home-testi-image {
    margin: 0 !important;
    padding: 0 !important;
  }
  .StartedSection {
    background-color: #E6E1FC;
    height:auto;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .home-testi-image img{
    max-width: 100%;
    height:auto;
    display: flex;
  
  }
  
  .testimonial-cards {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 90%; /* Adjust this value as needed to control the width */
  }
  .col-md-6.col-sm-12.d-flex.flex-wrap {
    justify-content: center;
  }
  .approve-text, .about-text, .service-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .about-gridDot {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 240px;
    /* background-color: #040018; */
  }
  .service-content {
    margin: 20px;
  }
  .service-btn {
    margin: 15px;
  }
  .homeContainer {
    margin-top: 80px;
    background-color: #243B8D;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
  }
  .homeText {
    color: #EBEBED;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .homeText .mainTitle {
    color: #EBEBED;
    font-size: 36px;
    font-weight: 400;
    text-align: left;
    max-width: 100%;
  }
  .lists {
    text-align: center;
  }
  .container-list, .container-list-one {
    justify-content: center;
  }
  .col-md-2, .col-md-8, .col-sm-2, .col-sm-8 {
    flex: 0 0 auto;
    text-align: center;
  }
  .justify-content-start {
    display: flex;
    justify-content: flex-start;
  }
  .round-buttons-container {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  .round-button {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
  }
  .round-button.yellow {
    background-color: #9780F2;
    z-index: 1;
  }
  .round-button.green {
    background-color: #FFD028;
    z-index: 0;
  }
  .containerSection {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-color: #fff;
    height: auto;
  }
  .horizontal-line {
    margin-right: 25%;
    background-color: #B6BED9;
  }
  .small-button, .smallyellow-button, .round-buttonContent {
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    padding: 8px 16px;
  }
  .section-three, .service-section, .privacy-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .aboutSection, .about-content, .card-section, .lists, .footer {
    margin: 0 auto;
    justify-content: center !important;
    padding: 0;
    width: 100%;
  }
  .card, .testimonial-cards, .testimonial-cards-one {
    width: 100%;
    margin: 20px 0;
  }
  .testimonial-text, .testimonial-text-one {
    text-align: center;
  }
  .icon-box, .round-buttonContent {
    width: 40px;
    height: 40px;
  }
  .phone-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .list-text-one, .list-text, .testimonial-name, .testimonial-name-one {
    font-size: 16px;
    font-weight: 600;
  }
}

